<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
      class="mt-3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <parte-firmar-form
          v-if="dbReady"
          :id="routeParams.idparte_trabajo"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteFirmarForm from './components/ParteFirmarForm'
import Data from './ParteFirmarData'

export default {
  components: {
    ParteFirmarForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      parteTrabajo: {},
    }
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = `Firmar parte Nº${this.routeParams.idparte_trabajo}`
    },
    async formInvalid () {
      if (!this.formData.firma) {
        this.$alert.showSnackbarError('Debes firmar para dar tu consentimiento')
      }
    },
    async submitForm () {
      const parteTrabajo = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
      if (this.formData.firmarParte) {
        await Data.firmarParte(
          this,
          this.routeParams.idparte_trabajo,
          this.formData,
          parteTrabajo.parte_trabajo.idorden_trabajo
        )
      }
      if (this.formData.firmarChecklist) {
        await Data.firmarChecklist(
          this,
          parteTrabajo.checklist_ot.idchecklist_ot,
          this.formData
        )
      }
      this.$appRouter.go(-1)
    }
  }
}
</script>
