import { decodeBase64 } from '@/utils/router'
import { get } from 'vuex-pathify'
import _ from '@/utils/lodash'
import { beforeRouteLeaveCommon } from './common'
import { hasViewPerm, hasInsertPerm, hasEditPerm, hasDeletePerm, hasPerm, permissions } from '@/utils/permissions'
import { initSchema, connect } from '@/offline/database'

export const basePageMixin = {
  data () {
    return {
      title: '',
      routeParams: null,
      routeQuery: null,
      loadingData: false,
      rememberState: false,
      rememberScroll: false,
      showingDialogs: {},
      pageLoaded: false,
      bottomNavBarButtons: [],
      dbReady: false,
    }
  },
  created () {
    this.routeParams = _.cloneDeep(this.$route.params)
    this.routeQuery = _.cloneDeep(this.$route.query)
    if (this.routeQuery.extra) {
      this.routeQuery.extra = decodeBase64(this.routeQuery.extra)
    }
  },
  computed: {
    routeFromBrowserHistory () {
      // no mostrar alerts en el "back" del navegador
      return this.$route.meta.fromBrowserHistory
    },
    userPermissions: get('usuario/permisos'),
    permissions () {
      return permissions
    },
  },
  methods: {
    async initDB () {
      if (!this.$offline.db) {
        this.$loading.showManual('Inicializando base de datos...')
        try {
          await this.$offline.init(await connect(initSchema()))
        } finally {
          this.dbReady = true
          this.$loading.hide()
        }
      } else {
        this.dbReady = true
      }
    },
    async closeDB () {
      if (this.$offline.db) {
        await this.$offline.db.close()
        this.$offline.db = null
        this.dbReady = false
      }
    },
    async copyRouteParamsToPageStore () {
      if (this.pageStoreName) {
        for (const routeParam in this.routeParams) {
          if (this.storeHasProperty(routeParam)) {
            await this.setStoreProperty(routeParam, this.routeParams[routeParam])
          }
        }
      }
    },
    async initStore () {
      // routeParams -> ¿existe en el store de la página? -> copiar valor
      // usado para: nextRoute, readonly
      await this.copyRouteParamsToPageStore()
    },
    storeHasProperty (property) {
      return property in this.$store.get(`${this.pageStoreName}`)
    },
    async setStoreProperty (property, value) {
      await this.$store.set(`${this.pageStoreName}/${property}`, value)
    },
    async getStoreProperty (property) {
      return await this.$store.get(`${this.pageStoreName}/${property}`)
    },
    async dispatchStore (actionName, payload) {
      return await this.$store.dispatch(`${this.pageStoreName}/${actionName}`, payload)
    },
    hasViewPerm (perm) {
      return hasViewPerm(this.userPermissions, perm)
    },
    hasInsertPerm (perm) {
      return hasInsertPerm(this.userPermissions, perm)
    },
    hasEditPerm (perm) {
      return hasEditPerm(this.userPermissions, perm)
    },
    hasDeletePerm (perm) {
      return hasDeletePerm(this.userPermissions, perm)
    },
    hasPerm (perm) {
      return hasPerm(this.userPermissions, perm)
    },
    beforeRouteLeaveBase (to, from, next) {
      if (!beforeRouteLeaveCommon(this, to, from, next)) {
        next()
      }
    },
    initNavigationBottom () {
      this.bottomNavBarButtons = []
    },
    addNavigationBottomButton (title, name, icon, visible = true, badge = null, badgeColor = 'green') {
      const index = this.bottomNavBarButtons.push({
        title,
        name,
        icon,
        order: this.bottomNavBarButtons.length + 1,
        visible,
        badge,
        badgeColor,
      })
      return this.bottomNavBarButtons[index - 1]
    },
  }
}
